<template>
  <!-- 重新分配弹层 -->
  <div class="groupConfig">
    <el-dialog
      title="重新分配"
      :visible.sync="dialogVisible"
      width="500px"
      top="18vh"
      :show-close="true"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div class="searchBox">
        <el-select
          v-model="val"
          placeholder="请选择分组名称"
          clearable
          @change="searchBtn"
        >
          <el-option
            v-for="item in groupList"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
        <el-button type="primary" size="small" @click="searchBtn"
          >查询</el-button
        >
      </div>
      <div class="contentBox">
        <div
          class="playerBox"
          :class="{ active: currentIndex === index }"
          v-for="(item, index) in data1"
          @click="itemClick(item, index)"
          :key="index"
        >
          <div :class="{ isYourself: item.groupId === myGroupId }">
            <div class="iBox">{{ item.name }}-{{ item.siteTargetName }}</div>
            <div class="nameBox">{{ item.playerName }}</div>
            <!-- <div class="sumBox">{{ item.siteTargetNumber }}</div> -->
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" :loading="loading" @click="handelConfirm"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getSelectAllGroup, reassign, getSelectTargetIdBycomId, getTargetSiteNumber } from '@/api/eventManagement/competitionProject/index.js'
export default {
  inject: ['reload'],
  components: {},
  props: {
    // groupCount: {
    //   type: Number,
    //   required: true
    // },
    // siteTargetCount: {
    //   type: Number,
    //   required: true
    // }
  },
  data() {
    return {
      dialogVisible: false,
      currentIndex: -1,// 记录状态的变量
      loading: false,

      // -------------------------
      val: '',
      groupList: [],
      data1: [],
      data: [],
      page: {
        competitionId: parseInt(sessionStorage.getItem('competitionId')),
        name: "",
        playerName: ""
      },
      allGroupList: [],
      updareData: {
        // competitionId: parseInt(sessionStorage.getItem('competitionId')), // 比赛id
        newName: "", // 新的第几组
        newgroupId: null, // 新的分组id，没有不传
        newsiteTargetId: null, // 新的靶位id
        oldName: "", // 自己的第几组
        oldgroupId: null, // 自己的分组id
        oldsiteTargetId: null, // 自己的靶位id
        relationId: null // 关联id
      },
      targetList: [], // 靶位列表
      targetSiteNumber: [],
      myGroupId: null
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getSelectTargetIdBycomId();
  },
  mounted() { },
  methods: {
    // 查询所有分组不分页
    async getSelectAllGroup() {
      const res = await getSelectAllGroup({ relationId: this.updareData.relationId });
      // console.log(res);
      if (res.status === 200) {
        this.allGroupList = res.data;
        // console.log(this.allGroupList);
        let groupList11 = [];
        this.allGroupList.forEach(item => {
          if (groupList11.indexOf(item.name) === -1) {
            groupList11.push(item.name);
          }
        })
        // console.log(groupList11);
        if (groupList11.length > 0) {
          this.groupList = this.generateData(groupList11);
        }
        // console.log(this.targetSiteNumber);
        this.groupList.forEach(item1 => {
          this.targetSiteNumber.forEach(item => {
            this.data1.push({
              groupId: null,
              competitionId: parseInt(sessionStorage.getItem('competitionId')),
              competitionType: "",
              name: item1.label,
              nameId: null,
              playerId: null,
              playerName: "",
              siteTargetId: item.id,
              siteTargetName: item.name + '',
              siteTargetNumber: ""
            });
          })
        })
        // console.log(this.data1);
        // console.log(this.allGroupList);

        this.data1.forEach(item => {
          this.allGroupList.forEach(item1 => {
            if (item.name === item1.name && item.siteTargetName === item1.siteTargetName) {
              // console.log('222');
              item.groupId = item1.id;
              // item.competitionId = item1.competitionId;
              item.competitionType = item1.competitionType;
              item.nameId = item1.nameId;
              item.playerId = item1.playerId;
              item.playerName = item1.playerName;
              item.siteTargetId = item1.siteTargetId;
              item.siteTargetNumber = item1.siteTargetNumber;
            }
          })
        })
        this.data1.forEach(item => {
          this.targetList.forEach(item1 => {
            if (parseInt(item.siteTargetName) === item1.name) {
              item.siteTargetId = item1.id;
            }
          })
        })
      }
    },
    // 根据比赛id靶位Id
    async getSelectTargetIdBycomId() {
      const res = await getSelectTargetIdBycomId({ competitionId: this.page.competitionId });
      // console.log(res);
      if (res.status === 200) {
        this.targetList = res.data;
      }
    },
    // 分组数据处理
    generateData(val) {
      const data = [];
      for (let i = 0; i < val.length; i++) {
        data.push({
          key: i,
          // label: `第${i}组`
          label: val[i]
        });
      }
      return data;
    },
    // 编辑
    itemClick(item, index) {
      // console.log(item);
      if (item.groupId === this.myGroupId) {
        return this.$message({
          showClose: true,
          message: '请选择其他靶位！',
          type: 'warning'
        });
      }
      this.updareData.newName = item.name;
      this.updareData.newgroupId = item.groupId;
      this.updareData.newsiteTargetId = item.siteTargetId;
      // console.log(index);
      this.currentIndex = index;
    },
    // 打开当前模态框
    async openAdd(val) {
      console.log(val);
      this.val = '';
      this.myGroupId = val.groupId
      const res = await getTargetSiteNumber({siteId: val.siteId, matchType: val.matchType});
      if (res.status === 200) {
        this.targetSiteNumber = res.data;
      }
      this.updareData.relationId = val.relationId;
      this.currentIndex = -1;
      this.data1 = [];
      this.getSelectAllGroup();
      this.data = this.data1;
      // console.log(this.data);
      // this.updareData.competitionId = val.competitionId;
      this.updareData.oldName = val.groupName;
      this.updareData.oldgroupId = val.groupId;
      this.updareData.oldsiteTargetId = val.siteTargetId;
      this.dialogVisible = true;
    },
    // 搜索按钮
    searchBtn() {
      if (this.val === '') {
        this.data1 = this.data;
      } else {
        this.data1 = this.data.filter(item => {
          return item.name === this.val;
        });
      }
      // console.log(this.data1);
    },
    // 取消
    close() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.close();
    },
    // 点击提交
    async handelConfirm() {
      this.loading = true;
      // console.log(this.updareData);
      const res = await reassign(this.updareData);
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          showClose: true,
          message: '编辑成功！',
          type: 'success'
        });
        this.loading = false;
        this.$emit('getSelectGroup');
        this.dialogVisible = false;
        this.reload(); // 需要刷新页面
      } else {
        this.$message({
          showClose: true,
          message: '编辑失败！' + res.msg,
          type: 'error'
        });
        this.loading = false;
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.groupConfig {
  .searchBox {
    margin-bottom: 20px;
    ::v-deep.el-input {
      width: 180px;
      // margin-right: 0;
    }
  }
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    border-radius: 999px;
    border: 5px solid transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2) inset;
  }

  ::-webkit-scrollbar-thumb {
    min-height: 20px;
    background-clip: content-box;
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2) inset;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  .contentBox {
    width: 100%;
    max-height: 400px;
    display: flex;
    flex-flow: wrap;
    overflow-y: auto;
    .playerBox {
      cursor: pointer;
      width: 100px;
      height: 100px;
      background: #ffffff;
      color: #7d7d7d;
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.23);
      border-radius: 8px;
      margin-right: 24px;
      margin-bottom: 20px;
      .iBox {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        margin-top: 10px;
      }
      .nameBox {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .sumBox {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
      }
      .isYourself {
        width: 100px;
        height: 100px;
        border: 1px solid #f00;
        border-radius: 8px;
        color: #fff;
        background-color: red;
      }
    }
    .active {
      color: #fff;
      background-color: #2bd377;
    }
  }
  ::v-deep.el-dialog__body {
    margin-top: 20px !important;
  }
  ::v-deep.el-button--default {
    background: #eaefff;
    color: #06206b;
    border: none;
  }
  ::v-deep.el-button--primary {
    margin-left: 40px !important;
    background: #06206b;
    border: none;
  }
}
</style>
