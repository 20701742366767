import request from '@/utils/request';

// 通过比赛id查询分组列表
export function getSelectGroupByComId(data) {
  return request({
    url: '/group-management/selectGroupByComId',
    method: 'post',
    data
  });
}

// 选择比赛id开始分组
export function startGroup(params) {
  return request({
    url: '/group-management/startGroup',
    method: 'get',
    params
  });
}